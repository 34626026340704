import Section from "../common/Section";
import Contact from "../dashboard/Contact";


const ContactPage = () => {
    return (
        <div className="w-full">
            <Section id={"home"}>
                <div className="w-full min-h-[80vh] border-t-2">
                    <div className={"h-full w-full justify-items-center items-start pt-14 grid grid-cols-1"}>
                        <Contact/>
                    </div>
                </div>
            </Section>
        </div>
    );
}

export default ContactPage