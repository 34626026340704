import Navigation from "./components/Navigation";
import Dashboard from "./components/Dashboard";
import DataContext from "./dataStorage/DataProvider";
import {useContext} from "react";
import {HashRouter, Routes, Route} from "react-router-dom";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import ContactPage from "./components/pages/ContactPage";

function App() {
    const {darkMode} = useContext(DataContext);

    return (
        <HashRouter>
            <div className={`h-full w-full mx-auto py-2
                    ${darkMode ? "dark" : ""}`}>
                <div className={"flex flex-col justify-start bg-background-light dark:bg-background-dark"}>
                    <div className={"w-screen top-0 text-dark-1 dark:text-light-1"}> {/*sticky*/}
                        <Navigation/>
                    </div>
                    <div className="w-full">
                        <Routes>
                            <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route exact path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                            <Route exact path="/contact" element={<ContactPage/>}/>
                            <Route path="/*" element={<Dashboard/>}/>
                            {/*<Route path="*" element={<a>Page Not Found</a>}/>*/}
                        </Routes>
                    </div>

                    <div>
                        <Footer/>
                    </div>
                </div>
            </div>
        </HashRouter>
    );
}

export default App;
