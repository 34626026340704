import {DATA} from "../../data/Data"

const Home = () => {
    return (
        <div className={"h-full w-full flex items-center"}>
            <div className={"w-full grid gap-4 justify-items-center text-center"}>
                <h5 className="text-5xl font-bold text-dark-3 dark:text-light-1">
                    {DATA.about.header}
                </h5>
                <p className="mb-5 text-2xl grid gap-2 text-dark-1 dark:text-light-3 sm:text-2xl">
                    {DATA.about.info}
                </p>
            </div>
        </div>
    )
}

export default Home