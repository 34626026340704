import {Sidebar} from 'flowbite-react/lib/cjs/components';
// import {HiHome, HiLink, HiCube} from "react-icons/hi2";
import {HiHome, HiLink, HiChevronDown, HiMoon, HiSun, HiCube, HiMail, HiPuzzle, HiChevronUp} from "react-icons/hi";
import {DATA} from "../data/Data"
import project from "./dashboard/Project";
import {useContext, useState} from "react";
import DataContext from "../dataStorage/DataProvider";
import {openExternalLink} from "../utils";
import {nanoid} from "nanoid";
import {Link} from "react-router-dom";


const iconStyle = {color: "currentColor", fontSize: "1.25rem"}
const dropDownIconStyle = {color: "currentColor", fontSize: "1.5rem"}
const MenuItem = ({href, onClick, noIntent, ...props}) => (
    <li>
        <Link to={href}
            onClick={() => onClick !== undefined && onClick()}
           className="menu-item hover:menu-item-hover flex space-x-3 items-center p-2 rounded-lg">

            {props.icon !== undefined ? <props.icon style={iconStyle}/> : noIntent === undefined && <div className={"w-5 h-6"}/>}

            {props.children !== undefined &&
                <span>{props.children}</span>
            }
        </Link>
    </li>
)

const MenuCollapse = ({label, ...props}) => {
    const [collapsed, setCollapsed] = useState(true)
    return (
        <li>
            <button type="button"
                    className="menu-collapse-button flex space-x-3 items-center p-2 w-full rounded-lg"
                    onClick={() => setCollapsed(prevState => !prevState)}
            >
                {props.icon !== undefined && <props.icon style={iconStyle}/>}
                <span className="flex-1 text-left whitespace-nowrap">
                    {label}
                </span>
                {collapsed ? <HiChevronDown style={dropDownIconStyle}/> : <HiChevronUp style={dropDownIconStyle}/>}

            </button>
            {collapsed === false &&
                <ul id="dropdown-example" className="py-2 space-y-2">
                    {props.children}
                </ul>
            }
        </li>
    )
}

const DarkModeToggle = ({darkMode, setDarkMode, ...props}) => {
    return (
        <li>
            <div
                className="flex space-x-3 items-center p-2 text-base font-normal rounded-lg hover:bg-gray-100 hover:text-gray-700"
                onClick={() => setDarkMode(!darkMode)}>

                <label className="inline-flex relative items-center cursor-pointer">
                    <div
                        className="dark-mode-toggle w-14 h-8 rounded-full relative border border-2
                        grid items-center">
                        {darkMode ? <props.lightModeIcon className={"absolute left-2"} style={iconStyle}/> :
                            <props.darkModeIcon className={"absolute right-2"} style={iconStyle}/>}
                    </div>
                    {/*<span*/}
                    {/*    className="ml-3 text-sm font-medium">{darkMode ? "Light Mode" : "Dark Mode"}*/}
                    {/*</span>*/}
                </label>
            </div>
        </li>
    )
}
const Navigation = () => {
    const {darkMode, setDarkMode} = useContext(DataContext);
    return (
        <header className={"bg-white dark:bg-dark-1 border-b-2 border-dark-1 dark:border-light-1"}>
            <nav className="p-2">
                <ul className="gap-2 flex md:justify-center md:items-start flex-col md:flex-row">
                    <div className={"order-first md:order-last"}>
                        <DarkModeToggle darkMode={darkMode} setDarkMode={setDarkMode} darkModeIcon={HiMoon}
                                        lightModeIcon={HiSun}/>
                    </div>
                    <MenuItem href="/home" noIntent>Home</MenuItem>

                    {DATA.projectCollections.map(projectCollection =>
                        <MenuCollapse
                            icon={projectCollection.icon}
                            label={projectCollection.header}
                            key={nanoid(6)}
                        >
                            {projectCollection.projects.map(project =>
                                <MenuItem
                                    href={`/${projectCollection.href}-${project.href}`}
                                    key={nanoid(6)}
                                >
                                    {project.name}
                                </MenuItem>
                            )}

                        </MenuCollapse>
                    )}

                    {/*{DATA.socialLinks.map(socialLink =>*/}
                    {/*    <MenuItem*/}
                    {/*        onClick={() => openExternalLink(socialLink.link)}*/}
                    {/*        icon={HiLink}*/}
                    {/*    >*/}
                    {/*        {socialLink.name}*/}
                    {/*    </MenuItem>*/}
                    {/*)}*/}
                    <MenuItem href="/contact" noIntent>Contact</MenuItem>
                </ul>
            </nav>
        </header>
    );
}

export default Navigation