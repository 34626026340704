import {createContext, useEffect, useState} from "react";

const DataContext = createContext({})

const fetchLocal = ({key, defaultValue}) => {
    const saved = JSON.parse(localStorage.getItem(key));
    if (saved !== undefined && saved !== null && saved.length > 0)
        return saved;
    return defaultValue;
}
const saveLocal = ({key, value}) => {
    localStorage.setItem(key, JSON.stringify(value));
}
export const DataProvider = ({children}) => {
    const [darkMode, setDarkMode] = useState(fetchLocal({key: "darkMode", defaultValue: false}));

    useEffect(() => {
        saveLocal({key: "darkMode", value: darkMode});
    }, [darkMode])

    return (
        <DataContext.Provider value={{darkMode, setDarkMode}}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext