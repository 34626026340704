import {DATA} from "../../data/Data"


const Contact = () => {
    const handleSendMail = () => {
        window.location.href = `mailto:${DATA.mailTemplate.mail}?subject=${DATA.mailTemplate.subject || ""}&body=${DATA.mailTemplate.body || ""}`;
    }
    return (
        <div className={"h-full w-full flex items-center"}>
            <div className={"w-full grid gap-4 justify-items-center"}>

                <h5 className="text-4xl font-bold text-dark-3 dark:text-light-1">
                    Contact
                </h5>
                <p className="mb-5 text-xl text-center text-dark-1 hover:text-blue-500 dark:text-light-3 sm:text-xl">
                    <a href={DATA.mailTemplate.mail}>
                        {DATA.mailTemplate.mail}
                    </a>
                </p>
                <button
                    //rounded-3xl
                    className={" border-solid border-2 text-dark-2 dark:text-light-2 border-dark-2 dark:border-light-2 hover:border-dark-2 dark:hover:border-light-2 hover:bg-dark-2  dark:hover:bg-light-2 hover:text-light-2 dark:hover:text-dark-2 hover:scale-105 transform transition duration-300"}
                    onClick={handleSendMail}
                >
                    <div className={"px-4 py-2"}
                    >
                        Send Email
                    </div>
                </button>
            </div>
        </div>
    )
}

export default Contact