import {nanoid} from "nanoid";
import {HiCube, HiPuzzle} from "react-icons/hi";
import {FaLink, FaApple, FaGooglePlay} from "react-icons/fa";

import PyramidPuzzleCover from "../resources/images/pyramid-puzzle-cover.png"

const youtube = ({src}) => (
    <div className={"bg-red-200 relative w-[100vh] h-0 pb-[56.25vh]"}>
        <iframe className={"bg-red-500 absolute w-full h-full left-0 top-0"}
                src={src} frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
    </div>
)
const image = ({src, alt, noClassName}) => (
    <img
        className={!noClassName && "relative w-full h-full sm:max-h-[50vh]"}
        src={src}
        alt={alt}
    />
)

const GOOGLE_PLAY_STORE = {message: "Get on", storeName: "Google Play", icon: FaGooglePlay}
const APP_STORE = {message: "Download on", storeName: "App Store", icon: FaApple}
const WEBSITE = {message: "", storeName: "Go to Website", icon: FaLink}
const APPLICATION = {message: "", storeName: "Go to Application", icon: FaLink}

export const DATA = {
    website: "https://petunia.simplefuntime.com/",
    about: {
        header: "Petunia Studio",
        info: "Hi, we develop games.",
    },
    mailTemplate: {
        mail: "contact.petunia@simplefuntime.com",
        subject: "Petunia Contact",
        body: "hi :)",
    },
    projectCollections: [
        {
            href: "games",
            header: "Games",
            projects: [
                {
                    href: "pyramid-puzzle",
                    name: "Pyramid Puzzle",
                    info: <div>
                        Complete Puzzles to Get Pyramid!
                        <br/>
                        Find pieces and place them!
                    </div>,
                    cover: {
                        src: PyramidPuzzleCover,
                        alt: "hammer roll game image"
                    },
                    storeLinks: [
                        {link: "https://apps.apple.com/au/app/hammer-roll/id1582789481", ...APP_STORE},
                        {link: "https://play.google.com/store/apps/details?id=com.PeraGames.HammerRoll", ...GOOGLE_PLAY_STORE},
                    ],
                },
            ]
        },
    ],
    socialLinks: [
        {name: "Google Play Store", link: "https://www.google.com"},
    ]
}