import Home from "./dashboard/Home";
import Contact from "./dashboard/Contact";
import {DATA} from "../data/Data"
import Popup from "./common/Popup";
import {Fragment, useState} from "react";
import Section from "./common/Section";
import Carousel from "./common/Carousel";
import {openExternalLink} from "../utils";
import {nanoid} from "nanoid";


const Dashboard = () => {
    const [popUpEnabled, setPopUpEnabled] = useState(true);
    const handlePopupClose = () => {
        setPopUpEnabled(false)
    }
    return (
        <div className="w-full">
            <Section id={"home"}>
                <div className="w-full h-[80vh] min-h-0 border-t-2">
                    <Home/>
                </div>
            </Section>

            {DATA.projectCollections.map(projectCollection => (
                <Section key={nanoid(6)} id={projectCollection.header.href}>
                    <div className="w-full min-h-max border-t-2">

                        <div className={" pt-8 w-full grid gap-4 text-center"}>
                            <h5 className="mb-2 text-4xl font-bold text-dark-3 dark:text-light-1">
                                {projectCollection.header}
                            </h5>
                            <div className="mb-5 text-xl text-dark-1 dark:text-light-3 sm:text-xl">
                                {projectCollection.info}
                            </div>
                            {projectCollection.link &&
                                <a href={projectCollection.link} className="mb-5 text-xl font-bold underline text-dark-1 dark:text-light-3 sm:text-xl">
                                    {projectCollection.link}
                                </a>
                            }
                        </div>

                        <div className="w-full grid gap-8">
                            {projectCollection.projects.map((projectData, index) => (
                                <section key={nanoid(6)} id={`${projectCollection.href}-${projectData.href}`}
                                    className={"border-t-[3px] border-dashed"}>
                                    <div
                                        className={"gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6"}>
                                        <img className={`w-auto max-h-screen ratio border border-2 border-dark-1/50 dark:border-light-3/50 ${index % 2 === 1 && 'order-last'}`}
                                             src={projectData.cover !== undefined ? projectData.cover.src
                                                 : "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup.svg"}
                                             alt={projectData.cover !== undefined ? projectData.cover.alt
                                                 : "dashboard image"}/>

                                        {/*<div className="w-full">*/}
                                        {/*    {projectData.cover}*/}
                                        {/*    <Carousel>*/}
                                        {/*        {projectData.cover.map(mediaContent =>*/}
                                        {/*            mediaContent*/}
                                        {/*        )}*/}
                                        {/*    </Carousel>*/}
                                        {/*</div>*/}

                                        <div className={"mt-4 md:mt-0 grid gap-2 justify-items-center  text-center"}>
                                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-dark-3 dark:text-light-1"
                                            >{projectData.name}</h2>
                                            <div className="mb-6 font-light text-dark-1 text-xl dark:text-light-3"
                                            >{projectData.info}</div>

                                            {projectData.link &&
                                                <a href={projectData.link} className="mb-5 text-base font-bold underline text-dark-1 dark:text-light-3 sm:text-lg">
                                                    {projectData.link}
                                                </a>
                                            }

                                            {projectData.detailedInfo !== undefined &&
                                                <a href="#"
                                                   className="inline-flex w-full items-center justify-center rounded-lg bg-dark-2 px-4 py-2.5 text-light-1 hover:bg-dark-1 focus:outline-none focus:ring-4 focus:ring-light-3 dark:bg-dark-1 dark:hover:bg-dark-2 dark:focus:ring-dark-1 sm:w-auto">
                                                    More Details
                                                    <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor"
                                                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd"
                                                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                              clipRule="evenodd"></path>
                                                    </svg>
                                                </a>
                                            }
                                            {projectData.detailedInfo !== undefined && projectData.storeLinks !== undefined && projectData.storeLinks.length > 0 &&
                                                <div className={"pt-2"}/>
                                            }
                                            {projectData.storeLinks !== undefined && projectData.storeLinks.length > 0 &&
                                                    <div
                                                        className="items-center flex gap-4">
                                                        {projectData.storeLinks.map(storeLink =>
                                                            <div
                                                                key={nanoid(6)}
                                                                onClick={() => openExternalLink(storeLink.link)}
                                                                className="store-button inline-flex w-full items-center justify-center rounded-lg  px-4 py-2.5 sm:w-auto"
                                                            >
                                                                {storeLink.icon && <storeLink.icon className={"w-8 h-8"}/>}
                                                                <div className="text-left pl-2">
                                                                    <div className="text-xs">
                                                                        {storeLink.message}
                                                                    </div>
                                                                    <div
                                                                        className="font-sans text-sm font-semibold whitespace-nowrap">
                                                                        {storeLink.storeName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                </section>
                            ))}
                        </div>
                    </div>
                </Section>
            ))}

            <Section id={"contact"}>
                <div className="w-full h-[50vh] border-t-2">
                    <Contact/>
                </div>
            </Section>
        </div>
    );
}

export default Dashboard