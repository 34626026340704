import {DATA} from "../data/Data"
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="text-center border-t-2 pt-6 pb-2 grid gap-2">
            <p className={"dark:text-purple-400  text-purple-600 "}>
                <Link to="/privacy-policy" className={"dark:hover:text-purple-100 hover:text-purple-900"}>
                    Privacy Policy
                </Link>
                <a className={"text-dark-3 dark:text-light-3"}> | </a>
                <Link to="/terms-and-conditions" className={"dark:hover:text-purple-100 hover:text-purple-900"}>
                    Terms & Conditions
                </Link>

            </p>
            <p className="text-dark-3 dark:text-light-3">
                © 2022 Copyright: <a className="dark:text-purple-400 dark:hover:text-purple-200 text-purple-600 hover:text-purple-800" href={"/"}
            >Petunia Studio</a>
            </p>
        </div>
    );
}

export default Footer